import type TableConfig from '../../lib/types/tableConfigTypes'
import type { Session } from 'next-auth'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import React from 'react'
import InventoryIcon from '@mui/icons-material/Inventory'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import { formatDateString, capitalize } from '../../lib/utils/formatters'
import Link from '../Link'
import EditBoardOptions from './EditBoardOptions'
import { horizontalStickyStyles } from '@/lib/utils/sticky'

const tableConfig = (
    setOpenModal: React.Dispatch<React.SetStateAction<Partial<DTO.BoardModal> | null>>,
    session: Session | null,
    isExpanded = false
): TableConfig<DTO.BoardModal, unknown> => {
    let fields: TableConfigField<DTO.BoardModal>[] = [
        {
            label: 'Name',
            key: 'headline',
            sortColumn: 'headline',
            skeletonStyle: { xs: 60, md: 100 },
            headCellFormat: isExpanded ? { width: '300px' } : { ...horizontalStickyStyles('th') },
            format: isExpanded
                ? {
                      fontWeight: 'medium',
                      whiteSpace: 'nowrap',
                      maxWidth: '300px',
                  }
                : {
                      fontWeight: 'medium',
                      whiteSpace: 'nowrap',
                      ...horizontalStickyStyles('td'),
                  },
            select: (b) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Link
                        sx={{
                            maxWidth: { xs: '125px', md: 'initial' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        href={`/boards/${b.boardId}`}
                    >
                        {b.headline}
                    </Link>
                    {b.status === 'ARCHIVED' && (
                        <>
                            &nbsp;&nbsp;
                            <InventoryIcon style={{ color: 'darkgrey', fontSize: 'inherit' }} />
                        </>
                    )}
                    {!b.isComplete && b.parentId && !b.populateFromGraders && (
                        <Chip label="Inc" variant="filled" size="small" color="warning" sx={{ marginLeft: 1 }} />
                    )}
                </Box>
            ),
        },
        {
            label: 'Season',
            key: 'season',
            sortColumn: 'season',
            format: { whiteSpace: 'nowrap' },
            skeletonStyle: { xs: 50 },
            select: (b) => b.season,
        },
        {
            label: 'Last Modified',
            key: 'updatedAt',
            sortColumn: 'updatedAt',
            format: { whiteSpace: 'nowrap' },
            skeletonStyle: { xs: 50 },
            select: (b) => formatDateString(b.updatedAt as unknown as string, 'MMM D, YYYY', 'local'),
        },
        {
            label: 'Type',
            key: 'typeName',
            sortColumn: 'typeName',
            format: { whiteSpace: 'nowrap' },
            skeletonStyle: { xs: 60, md: 100 },
            select: (b) => b.typeName,
        },
        {
            label: 'Tag',
            key: 'tag',
            sortColumn: 'tagTitle',
            format: { whiteSpace: 'nowrap' },
            skeletonStyle: { xs: 60, md: 100 },
            select: (b) => (b.tag ? <Chip label={b.tag.title} variant="filled" size="small" /> : null),
        },
        {
            label: 'Item',
            key: 'entityType',
            sortColumn: 'entityType',
            skeletonStyle: { xs: 60, md: 100 },
            select: (b) => `${capitalize(b.entityType.toLowerCase())}s`,
        },
        {
            label: isExpanded ? 'Editor' : 'Editors',
            key: 'editors',
            skeletonStyle: { xs: 100, md: 400 },
            format: { whiteSpace: 'nowrap' },
            select: (b) => b.editors.map((u) => u.name).join(', '),
        },
        {
            label: 'Shared With...',
            key: 'userGroups',
            format: { whiteSpace: 'nowrap' },
            skeletonStyle: { xs: 100, md: 400 },
            select: (b) => b.audience.map((ug) => ug.name).join(', ') || 'Default Audience',
        },
        {
            label: '',
            key: 'edit',
            format: { position: 'relative', padding: 'none', width: '5%' },
            select: (board, _, { showEditAction }) => {
                const canEditBoardInfo =
                    board.createdBy === session?.userId || session?.roles.featurePermissions['board-management']
                return (
                    board.boardId === showEditAction &&
                    canEditBoardInfo && (
                        <EditBoardOptions
                            board={board}
                            setModal={setOpenModal}
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )
                )
            },
        },
    ]

    if (isExpanded) {
        fields = fields.filter((d) => ['updatedAt', 'headline'].includes(d.key as string))
    }

    return {
        size: 'small',
        initialSort: [
            { order: 'asc', orderBy: 'entityType' },
            { order: 'desc', orderBy: 'season' },
            { order: 'asc', orderBy: 'tagTitle' },
            { order: 'asc', orderBy: 'headline' },
        ],
        loadingSkeleton: {
            height: 330,
            numOfRows: 10,
        },
        fields,
    }
}

export default tableConfig
