import type { Session } from 'next-auth'
import type { FormikErrors, FormikHelpers, FormikProps } from 'formik'
import type { SelectChangeEvent } from '@mui/material/Select'
import type { ModuleExpandedList } from '@/lib/hooks/useBoard'
import React, { useState } from 'react'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListSubheader from '@mui/material/ListSubheader'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import SelectUser from '../../form/SelectUser'
import ErrorMessage from '../../form/ErrorMessage'
import { isSubmitButtonDisabled } from '../../form/formUtils'
import { useConstantsContext } from '../../../lib/contexts/ConstantsContext'
import SelectTag from './SelectTag'
import { listSubHeaderStyles } from '@/components/stats/TimeSeriesPlot'
import { boardModuleLabels, useModuleColumns } from '@/lib/hooks/useBoard'
import { asNumber } from '@/lib/utils/math'

const boardEntityTypes: { value: Enum.BoardEntityType; text: string }[] = [
    { value: 'PLAYER', text: 'Players' },
    { value: 'TEAM', text: 'Teams' },
]

const designationOptions: { value: Enum.BoardDesignation | 'NONE'; label: string }[] = [
    { value: 'NONE', label: 'None' },
    { value: 'AM_ASSIGNMENTS', label: 'Am. Assignments' },
    { value: 'PRO_ASSIGNMENTS', label: 'Pro Assignments' },
    { value: 'AM_MOCK_DRAFT', label: 'Am. Mock Draft' },
    { value: 'GLG_MOCK_DRAFT', label: 'G-League Mock Draft' },
    { value: 'PRO_TRADE_TIERS', label: 'Pro Trade Tiers' },
    { value: 'AM_PLAYER_RANKS', label: 'Am. Player Ranks' },
    { value: 'DRAFT_NIGHT', label: 'Draft Night' },
    { value: 'GLG_DRAFT_NIGHT', label: 'G-League Draft Night' },
    { value: 'AM_LEADERBOARD', label: 'Am. Leaderboard' },
]

type BoardModalFormColumnSelectorProps = Pick<
    FormikProps<Partial<DTO.BoardModal>>,
    'handleChange' | 'values' | 'isSubmitting' | 'touched' | 'errors'
> & {
    name: Extract<keyof DTO.BoardModal, 'listColumns' | 'positionColumns'>
    moduleGroups: [string, ModuleExpandedList<Enum.BoardColumns, Enum.BoardColumnModules>][]
}
const BoardModalFormColumnSelector = ({
    name,
    moduleGroups,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
}: BoardModalFormColumnSelectorProps) => (
    <>
        <FormGroup sx={{ maxHeight: '275px', overflowY: 'scroll' }} row onChange={handleChange} color="primary">
            {moduleGroups.map(([module, group]) => (
                <React.Fragment key={module}>
                    <Grid item xs={12} sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
                        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                        <ListSubheader sx={listSubHeaderStyles}>{group.title?.toUpperCase()}</ListSubheader>
                    </Grid>
                    {group.columns.map((b) => (
                        <Grid item xs={12} sm={6} key={b.type}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!values[name]?.includes(b.type)}
                                        value={b.type}
                                        name={name}
                                        disabled={isSubmitting}
                                    />
                                }
                                label={<Typography>{b.title}</Typography>}
                                key={b.type}
                                labelPlacement="end"
                            />
                        </Grid>
                    ))}
                </React.Fragment>
            ))}
        </FormGroup>
        {touched[name] && Boolean(errors[name]) && <ErrorMessage text="Select at least 1 column" />}
    </>
)

type BoardModalFormProps = {
    session: Session
    level: Enum.BoardLevels
    onSubmit: (values: Partial<DTO.BoardModal>, formikeHelpers: FormikHelpers<Partial<DTO.BoardModal>>) => void
    types: DTO.BoardType[] | undefined
    boardColumns: DTO.BoardColumn[] | undefined
    boardEntityType: Enum.BoardEntityType
    initialVals?: Partial<DTO.BoardModal> | null
    onClose: () => void
    setBoardEntityType: (type: Enum.BoardEntityType) => void
    tags?: DTO.BoardTag[]
}

const validationSchema = yup.object().shape({
    headline: yup.string().required('Headline is required'),
    listColumns: yup.array().ensure().min(1),
    positionColumns: yup.array().ensure().min(1),
    entityType: yup.string().required('Entity Type is required'),
    graders: yup.array().when(['populateFromGraders', 'parentId'], {
        is: (populateFromGraders: boolean, parentId: string | null) => populateFromGraders && !parentId,
        then: yup.array().min(1, 'At least 1 grader is required when populating from graders'),
        otherwise: yup.array().min(0),
    }),
    type: yup.string().required('Board Type is required'),
})
const BoardModalForm = ({
    session,
    level,
    onSubmit,
    types,
    boardColumns,
    boardEntityType,
    setBoardEntityType,
    initialVals,
    onClose,
    tags,
}: BoardModalFormProps): JSX.Element => {
    const isEditModal = !!initialVals?.boardId
    const isChildBoard = !!initialVals?.parentId
    const [showAdvanced, setShowAdvanced] = useState(false)
    const { defaultPrimaryProBoardType, defaultPrimaryAmatuerBoardType, draftYear, salaryYear } = useConstantsContext()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [boardId] = useState(isEditModal ? initialVals.boardId! : uuidv4())
    const [initEditors] = useState(
        session.user?.name
            ? [
                  {
                      name: session.user.name,
                      abbr: session.user.name,
                      entityId: session.entityId,
                      id: Number(session.userId),
                  },
              ]
            : []
    )
    const [, moduleGroups] = useModuleColumns({ filteredColumns: boardColumns, moduleLabels: boardModuleLabels })

    const formik = useFormik<Partial<DTO.BoardModal>>({
        validationSchema,
        onSubmit,
        initialValues:
            initialVals && Object.keys(initialVals).length
                ? initialVals
                : {
                      level,
                      boardId,
                      headline: '',
                      isRanked: true,
                      populateFromGraders: false,
                      listColumns: [] as Enum.BoardColumns[],
                      positionColumns: [] as Enum.BoardColumns[],
                      audience: [] as DTO.BoardAudienceUser[],
                      type: (level === 'Amateur'
                          ? defaultPrimaryAmatuerBoardType
                          : defaultPrimaryProBoardType) as Enum.BoardTypes,
                      status: 'ACTIVE' as Enum.BoardStatus,
                      createdBy: Number(session.userId),
                      editors: initEditors,
                      designations: [],
                      entityType: boardEntityType,
                      tag: null,
                      graders: [] as DTO.BoardGrader[],
                      season: level === 'Amateur' ? draftYear : salaryYear,
                      allowDuplicateItems: false,
                      author: null,
                      consensusTiers: [] as DTO.AddTierInput[],
                  },
    })

    const onEditorsChange = (e: SelectChangeEvent<unknown>): void => {
        const selectedEditors: DTO.BoardEditor[] = (e.target.value as DTO.User[]).map((x) => {
            const name = `${x.firstName} ${x.lastName}`
            return {
                name,
                abbr: name,
                entityId: x.entityId,
                id: x.id,
                boardId,
            }
        })
        if (!selectedEditors.some((x) => x.entityId === session.entityId)) {
            selectedEditors.push(...initEditors)
        }
        void formik.setFieldValue('editors', selectedEditors)
    }

    const onTagChange = (tag: DTO.BoardTag | null): void => {
        if (tag?.inputValue) {
            void formik.setFieldValue('tag', tag)
        } else if (tag?.title && !tag.inputValue) {
            void formik.setFieldValue('tag', { title: tag.title, inputValue: tag.title })
        } else {
            void formik.setFieldValue('tag', null)
        }
    }

    const onGradersChange = (e: SelectChangeEvent<unknown>): void => {
        const selectedGraders: DTO.BoardGrader[] = (e.target.value as DTO.User[]).map((x) => {
            const name = `${x.firstName} ${x.lastName}`
            return { id: x.id, name }
        })
        void formik.setFieldValue('graders', selectedGraders)
        if (selectedGraders.length > 0 && !formik.values.populateFromGraders) {
            void formik.setFieldValue('isRanked', true)
            void formik.setFieldValue('allowDuplicateItems', false)
        }
        if (selectedGraders.length > 0 && formik.values.populateFromGraders) {
            void formik.setFieldValue('allowDuplicateItems', true)
        }
    }

    const onAuthorChange = (e: SelectChangeEvent<unknown>): void => {
        const author = e.target.value as DTO.User
        const name = `${author.firstName} ${author.lastName}`
        void formik.setFieldValue('author', { id: author.id, name })
    }

    const handleDesignation = async (val: (Enum.BoardDesignation | 'NONE')[]) => {
        if (val.includes('NONE')) {
            await formik.setFieldValue('designations', [])
        } else {
            await formik.setFieldValue('designations', val)
        }
    }

    const canAddGraders = !!session.roles.featurePermissions['board-management']
    const isParentBoard = !!formik.values.graders?.length
    const isAggregateConsensusBoard = !!formik.values.populateFromGraders && !formik.values.consensusTiers?.length
    return (
        <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid sx={{ paddingTop: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                name="headline"
                                label="Board Name"
                                size="small"
                                placeholder="Enter Board Name"
                                fullWidth
                                value={formik.values.headline}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                error={formik.touched.headline && Boolean(formik.errors.headline)}
                                helperText={formik.touched.headline && formik.errors.headline}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectUser
                                name="editors"
                                label="Editors"
                                onChange={onEditorsChange}
                                value={formik.values.editors}
                            />
                        </Grid>

                        {!isChildBoard && canAddGraders && (
                            <Grid item xs={12}>
                                <SelectUser
                                    name="graders"
                                    label="Graders"
                                    onChange={onGradersChange}
                                    value={formik.values.graders}
                                />
                            </Grid>
                        )}

                        <Grid xs={12} sm={12} md={12} lg={12} item>
                            <FormControl fullWidth size="small">
                                <InputLabel id="type-label">Board Type</InputLabel>
                                <Select
                                    labelId="type-label"
                                    label="Board Type"
                                    name="type"
                                    onChange={formik.handleChange}
                                    value={formik.values.type}
                                >
                                    {types
                                        ?.filter((d) => d.level === (level === 'G-League' ? 'Pro' : level))
                                        // Remove G-League Focus Board Type
                                        .filter((d) => d.type !== (level === 'G-League' ? 'G_LEAGUE' : ''))
                                        .filter(
                                            (d) => d.entityType === formik.values.entityType || d.entityType === null
                                        )
                                        .map((u) => (
                                            <MenuItem value={u.type} key={u.type}>
                                                {u.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {formik.errors.type && <ErrorMessage text="Board Type is required" />}
                            </FormControl>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} item>
                            <FormControl fullWidth size="small">
                                <InputLabel id="tag-label" />
                                <SelectTag tags={tags || []} onChange={onTagChange} tagValue={formik.values.tag} />
                            </FormControl>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} item>
                            <FormControl fullWidth size="small">
                                <InputLabel id="type-label">Item Type</InputLabel>
                                <Select
                                    label="Entity Type"
                                    name="entityType"
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        setBoardEntityType(e.target.value as Enum.BoardEntityType)
                                        if (e.target.value !== formik.values.entityType) {
                                            void formik.setFieldValue('type', null)
                                        }
                                    }}
                                    value={formik.values.entityType}
                                    disabled={isEditModal}
                                >
                                    {boardEntityTypes.map((u) => (
                                        <MenuItem value={u.value} key={u.value}>
                                            {u.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormControl fullWidth size="small">
                                    <DatePicker
                                        views={['year']}
                                        label="Season"
                                        openTo="year"
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        value={dayjs().year(formik.values.season!)}
                                        onChange={(date: dayjs.Dayjs | null) =>
                                            formik.setFieldValue('season', date?.get('year'))
                                        }
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs="auto" mr={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={formik.handleChange}
                                            checked={!!formik.values.isRanked}
                                            name="isRanked"
                                            disabled={
                                                formik.isSubmitting ||
                                                ((isParentBoard || isChildBoard) && !formik.values.populateFromGraders)
                                            }
                                        />
                                    }
                                    label="Ranked"
                                />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                                if (e.target.checked) {
                                                    void formik.setFieldValue('allowDuplicateItems', true)
                                                }
                                            }}
                                            checked={!!formik.values.populateFromGraders}
                                            name="populateFromGraders"
                                            disabled={
                                                formik.isSubmitting || isEditModal || !isParentBoard || isChildBoard
                                            }
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            Populate from Graders
                                            <Tooltip title="Checking this box will create the board as a parent that is populated by the aggregate selection of items from pre-determined tiers of child/grader boards">
                                                <IconButton size="small">
                                                    <InfoIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    }
                                    sx={{ whiteSpace: 'nowrap' }}
                                />
                            </Grid>
                        </Grid>
                        {formik.values.populateFromGraders && !isChildBoard && (
                            <Grid item container xs={12} spacing={1}>
                                <FieldArray
                                    name="consensusTiers"
                                    render={({ push, remove }) => {
                                        const errors = formik.errors.consensusTiers as
                                            | FormikErrors<DTO.BoardModal['consensusTiers']>
                                            | undefined

                                        const addTierButton = (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    push({
                                                        name: '',
                                                        consensusLimit: null,
                                                        isReportRequired: true,
                                                    })
                                                    void formik.setFieldTouched(
                                                        `consensusTiers.${
                                                            formik.values.consensusTiers?.length || 0
                                                        }.isReportRequired`,
                                                        true
                                                    )
                                                }}
                                            >
                                                Add Tier
                                            </Button>
                                        )

                                        return !formik.values.consensusTiers?.length ? (
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={3} alignItems="center">
                                                    {addTierButton}
                                                    <TextField
                                                        value={formik.values.consensusLimit || ''}
                                                        name="consensusLimit"
                                                        onChange={formik.handleChange}
                                                        label="Board Item Limit"
                                                        size="small"
                                                        type="number"
                                                    />
                                                </Stack>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <FormLabel id="aggregateBoardTiers">
                                                        Aggregate Board Tiers
                                                    </FormLabel>
                                                </Grid>
                                                {formik.values.consensusTiers.map((t, i, self) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <React.Fragment key={i}>
                                                        <Grid item container xs={12} spacing={1}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={t.name}
                                                                    onChange={(e) =>
                                                                        formik.setFieldValue(
                                                                            `consensusTiers.${i}.name`,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    label="Tier"
                                                                    fullWidth
                                                                    size="small"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={t.consensusLimit}
                                                                    onChange={(e) =>
                                                                        formik.setFieldValue(
                                                                            `consensusTiers.${i}.consensusLimit`,
                                                                            asNumber(e.target.value)
                                                                        )
                                                                    }
                                                                    label="Item Limit"
                                                                    fullWidth
                                                                    size="small"
                                                                    type="number"
                                                                />
                                                                {errors?.[i]?.consensusLimit && (
                                                                    <ErrorMessage text="Max 3 digits" />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(e) =>
                                                                                formik.setFieldValue(
                                                                                    `consensusTiers.${i}.isReportRequired`,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            checked={
                                                                                formik.values.consensusTiers?.[i]
                                                                                    ?.isReportRequired ?? true
                                                                            }
                                                                            name={`consensusTiers.${i}.isReportRequired`}
                                                                        />
                                                                    }
                                                                    label="Report Required on Assignments"
                                                                    sx={{ whiteSpace: 'nowrap' }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton onClick={() => remove(i)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        {i === self.length - 1 && (
                                                            <Grid item xs={3}>
                                                                {addTierButton}
                                                            </Grid>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} lg={6}>
                            <Grid item xs={12}>
                                <FormLabel id="columns-list">Columns &ndash; List View</FormLabel>
                            </Grid>
                            <BoardModalFormColumnSelector
                                name="listColumns"
                                moduleGroups={moduleGroups}
                                values={formik.values}
                                handleChange={formik.handleChange}
                                touched={formik.touched}
                                errors={formik.errors}
                                isSubmitting={formik.isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid item xs={12}>
                                <FormLabel id="columns-position">Columns &ndash; Position View</FormLabel>
                            </Grid>
                            <BoardModalFormColumnSelector
                                name="positionColumns"
                                moduleGroups={moduleGroups}
                                values={formik.values}
                                handleChange={formik.handleChange}
                                touched={formik.touched}
                                errors={formik.errors}
                                isSubmitting={formik.isSubmitting}
                            />
                        </Grid>
                        <Grid item container xs={12} columnSpacing={2} alignItems="baseline">
                            <Grid item xs="auto">
                                <Typography variant="h6">Advanced</Typography>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => setShowAdvanced(!showAdvanced)}
                                    disabled={formik.isSubmitting}
                                >
                                    {showAdvanced ? 'Hide' : 'Show'} Advanced
                                </Button>
                            </Grid>
                        </Grid>
                        {showAdvanced && (
                            <>
                                <Grid xs={12} item>
                                    <SelectUser
                                        name="author"
                                        label="Author"
                                        onChange={onAuthorChange}
                                        value={formik.values.author}
                                        multiple={false}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <SelectUser
                                        name="audience"
                                        label="Audience"
                                        onChange={(e) => formik.setFieldValue('audience', e.target.value)}
                                        value={formik.values.audience}
                                        includeUserGroups
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="designation-label">Designation</InputLabel>
                                        <Select
                                            labelId="designation-label"
                                            label="Designation"
                                            name="designation"
                                            onChange={(e) =>
                                                handleDesignation(e.target.value as (Enum.BoardDesignation | 'NONE')[])
                                            }
                                            multiple
                                            value={formik.values.designations}
                                        >
                                            {designationOptions.map((d) => (
                                                <MenuItem
                                                    value={d.value}
                                                    key={d.value}
                                                    sx={{ fontStyle: d.value === 'NONE' ? 'italic' : 'none' }}
                                                >
                                                    {d.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={formik.handleChange}
                                                checked={
                                                    !isAggregateConsensusBoard && formik.values.allowDuplicateItems
                                                }
                                                name="allowDuplicateItems"
                                                disabled={
                                                    formik.isSubmitting ||
                                                    isEditModal ||
                                                    isChildBoard ||
                                                    isParentBoard ||
                                                    isAggregateConsensusBoard
                                                }
                                            />
                                        }
                                        label={`Allow Duplicate ${
                                            formik.values.entityType === 'PLAYER' ? 'Players' : 'Teams'
                                        }`}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => {
                            onClose()
                            formik.resetForm()
                        }}
                        disabled={formik.isSubmitting}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={formik.isSubmitting}
                        disabled={isSubmitButtonDisabled({
                            isSubmitting: formik.isSubmitting,
                            dirty: formik.dirty,
                            isValid: formik.isValid,
                            submitCount: formik.submitCount,
                        })}
                    >
                        {isEditModal ? 'Save' : 'Add'} Board
                    </LoadingButton>
                </DialogActions>
            </form>
        </FormikProvider>
    )
}

export default BoardModalForm
